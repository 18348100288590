/**
 * This handles landing page operations
 */
 window._smpr = window._smpr || {};

 window._smpr.landing = function () {

     let filter = {
         q1: null,
         q2: null,
         q3: null,
         q4: null,
         q5: null,
         q6: null
     };

     var init = function () {
        $(window).on("load", function () {
            //$('.loading img').addClass('animate__animated animate__fadeInUp');
            setTimeout(function () {
                $('.loading').hide();
            }, 500);
        });
         initHowToComponent();
         initQuestionComponent();
         initQuestionHandlers();
     };

     var initHowToComponent = function(){

         $('.how-to-prev').hide();
         $('.how-to .step').filter('[data-step=1]').show();
         $('.how-to .step').filter(':not([data-step=1])').hide();


         $('.how-to .close').on('click', function () {
             $('.how-to').attr('style', 'display:none !important');
         });

         $('input[type=radio][name=how-to-radio]').on('change', function () {
             $('.how-to .step').filter('[data-step=' + this.value + ']').show();
             $('.how-to .step').filter(':not([data-step=' + this.value + '])').hide();
         });

         $('.how-to-next').on('click', function () {
             let step = $('input[type=radio][name=how-to-radio]:checked').val();
             if (step != 4) {
                 if (step == 3) {
                     $(this).text('Begin');
                 } else {
                    $('.how-to-prev').show();
                    $(this).text('Next');
                 }
                 step++;
                 console.log(step);
                 console.log("input[type=radio][name=how-to-radio][value=" + (step) + "]");
                 $("input[type=radio][name=how-to-radio][value=" + step + "]").trigger('click');
             } else {
                 $('.how-to').attr('style', 'display:none !important');
             }

         });

         $('.how-to-prev').on('click', function () {
             let step = $('input[type=radio][name=how-to-radio]:checked').val();
             if (step != 1) {
                 if (step == 4) {
                     $('.how-to-next').text('Next');
                 } else if (step == 2) {
                     $(this).hide();
                 }

                 step--;
                 console.log(step);
                 console.log("input[type=radio][name=how-to-radio][value=" + (step) + "]");
                 $("input[type=radio][name=how-to-radio][value=" + step + "]").trigger('click');
             }

         });

         $('input[name=do-not-show-checkbox]').on('change', function() {
             if(this.checked) {
                 noMoreHowTo();
             }
         });

         var noMoreHowTo = function(){
             if (typeof(Storage) !== "undefined") {
                 window.localStorage.setItem('hideHowTo', 1);
             }
         };

         if(isHowToHidden()){
            $('.how-to').attr("style", "display:none!important");
         }
     };

     var initQuestionComponent = function(){
         $('.additional-question').hide();
         $('.questions-prev').hide();
         $('.questions-next').hide();
         $('.questions .step').filter('[data-step=1]').show();
         $('.questions .step').filter(':not([data-step=1])').hide();

         $('input[type=radio][name=questions-radio]').on('change', function () {
             $('.questions .step').filter('[data-step=' + this.value + ']').show();
             $('.questions .step').filter(':not([data-step=' + this.value + '])').hide();
         });

         $('.questions-next').on('click', function () {
             let step = $('input[type=radio][name=questions-radio]:checked').val();
             if (step != 3) {
                 if (step == 1) {
                     $('.questions-prev').show();
                     $(this).text('Finish');
                 }else if (step == 2) {
                     $('.questions .dots').hide();
                     $(this).text('Submit');
                 }
                 step++;
                 console.log(step);
                 console.log("input[type=radio][name=questions-radio][value=" + (step) + "]");
                 $("input[type=radio][name=questions-radio][value=" + step + "]").trigger('click');
             } else {
                 //load cola founts
                 //$('.questions').attr('style', 'display:none !important');
                 window.location = generateURL();
             }
             checkQuestionsByPage(step);
         });

         $('.questions-prev').on('click', function () {
             let step = $('input[type=radio][name=questions-radio]:checked').val();
             if (step != 1) {
                 if (step == 3) {
                    $('.questions-next').text('Finish');
                 }else if (step == 2) {
                    $(this).hide();
                    $('.questions-next').text('Next');
                 }
                 if(!$('.questions .dots').is(":visible")){
                     $('.questions .dots').show();
                 }
                 step--;
                 console.log(step);
                 console.log("input[type=radio][name=questions-radio][value=" + (step) + "]");
                 $("input[type=radio][name=questions-radio][value=" + step + "]").trigger('click');
             }
             checkQuestionsByPage(step);
         });

         checkQuestionsByPage();
     };

     var isHowToHidden = function(){
         if (typeof(Storage) !== "undefined") {
             return (window.localStorage.getItem('hideHowTo') == 1) ? true : false;
         }else{
             return false;
         }
     };

     var initQuestionHandlers = function(){

         $('input[type=radio][name=q1]').on('change', function(){
             filter.q1 = $(this).val();
             $('#outlet-type').text($('label[for='+  this.id  +']').text());
             checkQuestionsByPage($(this).data('page'));
         });

         $('input[type=radio][name=q2]').on('change', function(){
             filter.q2 = $(this).val();
             $('#stock-premium-mixers').text($('label[for='+  this.id  +']').text());

             if(filter.q2 == "4"){
                 $('input[type=radio][id="10"],input[type=radio][id="12"],input[type=radio][id="14"]').parent().show();
                 $('input[type=radio][id="11"],input[type=radio][id="13"],input[type=radio][id="15"],input[type=radio][id="16"]').parent().hide();
             }else{
                 $('input[type=radio][id="10"],input[type=radio][id="12"],input[type=radio][id="14"]').parent().hide();
                 $('input[type=radio][id="11"],input[type=radio][id="13"],input[type=radio][id="15"],input[type=radio][id="16"]').parent().show();
             }

             checkQuestionsByPage($(this).data('page'));
         });

         $('input[type=radio][name=q3]').on('change', function(){
             filter.q3 = $(this).val();
             $('#mixer-volume-liter').text($('label[for='+  this.id  +']').text());
             checkQuestionsByPage($(this).data('page'));
         });

         $('input[type=radio][name=q4]').on('change', function(){
             filter.q4 = $(this).val();
             $('#annual-bib-volume').text($('label[for='+  this.id  +']').text());
             checkQuestionsByPage($(this).data('page'));
         });

         $('input[type=radio][name=q5]').on('change', function(){
             filter.q5 = $(this).val();
             $('#soft-drink-founts').text($('label[for='+  this.id  +']').text());
             checkQuestionsByPage($(this).data('page'));
         });

         $('input[type=radio][name=q6]').on('change', function(){
             filter.q6 = $(this).val();
             $('#space-for-incremental-fount').text($('label[for='+  this.id  +']').text());
             /*console.log(filter.q6);
             if(filter.q6 == '15'){
                $('.additional-question').show();
             }else{
                $('.additional-question').hide();
             }*/
             checkQuestionsByPage($(this).data('page'));
         });
     };

     var checkQuestionsByPage = function(page = 1){
         switch(page){
             case 1:
                 console.log(!filter.q1);
                 if(!filter.q1 || !filter.q2 || !filter.q3){
                     $('.questions-next').hide();
                 }else{
                     $('.questions-next').show();
                 }
                 break;
             case 2:
                 if(!filter.q4 || !filter.q5 || !filter.q6){
                     $('.questions-next').hide();
                 }else{
                     $('.questions-next').show();
                 }
                 break;
             case 3:
         }
     };

     var generateURL = function(){
        return APP_URL + '/select-cola-fount/' + filter.q1 + '/' + filter.q2 + '/' + filter.q3 + '/' + filter.q4 + '/' + filter.q5 + '/' + filter.q6 + '/';
     };

     var oPublic = {
         init: init
     };

     return oPublic;

 }();

 window._smpr.landing.init();
